import { SvgLoader } from "@store-platform/ui/icons"
import { cn } from "@store-platform/utils"

interface LoaderProps {
  className?: string
  size?: number
}

export function Loader(props: LoaderProps) {
  return (
    <SvgLoader
      className={cn("animate-spin text-gray-500", props.className)}
      width={props.size ?? 20}
      height={props.size ?? 20}
    />
  )
}
