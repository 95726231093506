"use client"

import NextLink, { LinkProps as NextLinkProps } from "next/link"
/*
 *** Analytics
 */
//  type linkActions = {
//   CLICK: "click",
//   OPEN: "open",
// }
import { cva } from "class-variance-authority"
import React from "react"
import { cn } from "@store-platform/utils"
import { useAnalytics } from "@store-platform/backend/api-client"

export type LinkTracker =
  | "none"
  // auth
  | "forgot_password"
  | "sign_up"
  | "login"
  // navigation
  | "return_home"
  | "navbar_list_app"

  // footer
  | "mobile_home_footer_browse"
  | "mobile_home_footer_search"
  | "mobile_home_footer_foryou"
  | "mobile_home_footer_developer"

  // sidebar
  | "sidebar_for_you"
  | "sidebar_browse"
  | "sidebar_profile"
  | "sidebar_settings"
  | "sidebar_developer"
  | "sidebar_install_app"
  | "sidebar_claimed_app"
  | "sidebar_list_favorites"
  | "sidebar_list_custom"
  // footer
  | "contact_support"
  | "discord_page"
  | "twitter_profile"
  // auth & footer
  | "privacy_policy"
  | "terms_of_service"
  // listing
  | "show_all_previews"
  | "about_the_developer"
  | "social_platform_twitter"
  | "social_platform_website"
  // profile
  | "social_profile_twitter"
  | "social_profile_linkedin"
  | "social_profile_personal_link"
  // developer
  | "open_install_modal_npm_doc"
  // mobile hamburger
  | "hamburger_list_app"
  | "hamburger_about"
  | "hamburger_profile"
  | "hamburger_settings"
  | "hamburger_list_favorites"
  | "hamburger_list_custom"
  // listing
  | "listing_dev_portal"
  // share
  | "share_view_to_twitter"
  | "share_view_to_text"
  // nav user menu
  | "nav_user_menu_profile"
  | "nav_user_menu_settings"

/*
 *** Variants
 */
export type LinkVariant = "default" | "blue" | "flex" | "naked" | "bubble"
export type LinkSize = "default" | "sm"

type LinkVariants = {
  variants: {
    variant: {
      [variant in LinkVariant]: string
    }
    size: {
      [size in LinkSize]: string
    }
  }
  defaultVariants: {
    variant: LinkVariant
    size: LinkSize
  }
}

const linkVariantsConfig: LinkVariants = {
  variants: {
    variant: {
      naked: "",
      default: "text-gray-500",
      blue: "text-blue-500 hover:text-blue-700",
      flex: "bg-white flex items-center justify-center rounded-md font-medium shadow-xs",
      bubble:
        "inline-flex gap-1 items-center justify-center whitespace-nowrap shadow-sm transition-colors disabled:pointer-events-none disabled:opacity-50 without-ring bg-blue-50 text-blue-500 hover:bg-blue-100 h-9 px-4 rounded-full text-xs",
    },
    size: {
      default: "text-sm",
      sm: "text-xs",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
}

const linkVariants = cva(
  "text-sm font-normal disabled:pointer-events-none disabled:opacity-50 without-ring",
  linkVariantsConfig,
)

/*
 *** Component
 */
export type LinkProps = {
  asExternal?: boolean
  newTab?: boolean
  tracker?: any //AnalyticsTracker
  variant: LinkVariant
  size?: LinkSize
  onClick?: (input: any) => void
} & React.AnchorHTMLAttributes<HTMLAnchorElement> &
  NextLinkProps
// const genWarnings = genWarningsFactory("Link", linkTrackers)
// const getAction = (asExternal) =>
//   asExternal ? linkActions.OPEN : linkActions.CLICK
const Link: React.FC<LinkProps> = React.forwardRef(
  (
    {
      asExternal = false,
      newTab = false,
      tracker,
      variant,
      size,
      className,
      ..._props
    },
    ref,
  ) => {
    const { trackEvent } = useAnalytics()
    const Comp: any = NextLink
    let props = _props as React.AnchorHTMLAttributes<HTMLAnchorElement>

    // external link or new tab
    if (asExternal) {
      const { ...rest } = props
      props = {
        ...rest,
        target: "_blank",
        rel: "noreferrer noopener",
      }
    } else if (newTab) {
      props.target = "_blank"
    }

    // styling
    const c =
      variant === "naked"
        ? className
        : cn(linkVariants({ variant, size, className }))

    return (
      <Comp
        className={c}
        ref={ref}
        {...props}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          if (tracker) trackEvent(tracker)
          props.onClick?.(e)
        }}
      />
    )
  },
)

Link.displayName = "Link"

export { Link, linkVariants }
