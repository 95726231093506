import * as React from "react"
import type { SVGProps } from "react"
const SvgXCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10 2.292a7.708 7.708 0 1 0 0 15.416 7.708 7.708 0 0 0 0-15.416M1.042 10a8.958 8.958 0 1 1 17.916 0 8.958 8.958 0 0 1-17.916 0"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M12.942 7.058a.625.625 0 0 1 0 .884l-5 5a.625.625 0 1 1-.884-.884l5-5a.625.625 0 0 1 .884 0"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M7.058 7.058a.625.625 0 0 1 .884 0l5 5a.625.625 0 1 1-.884.884l-5-5a.625.625 0 0 1 0-.884"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgXCircle
