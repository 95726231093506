"use client"

import * as Form from "@radix-ui/react-form"
import { clsx } from "clsx"
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  MouseEvent,
  ReactElement,
  useState,
} from "react"
import { UseFormRegisterReturn } from "react-hook-form"
import {
  cn,
  formErrorClassName,
  formSuccessClassName,
} from "@store-platform/utils"
import { Loader } from "../Loader/Loader"
import {
  SvgCheckCircle,
  SvgInfo,
  SvgLock,
  SvgXCircle,
} from "@store-platform/ui/icons"
import { debounce } from "lodash-es"
import { Button } from "../Button/Button"

export type FormFieldInputProps = {
  inputHtmlAttributes?: InputHTMLAttributes<HTMLInputElement>
  label: string
  caption?: {
    text: string
    icon?: ReactElement | false
    action?: {
      text: string
      onClick: (event?: MouseEvent<HTMLButtonElement>) => void
    }
    className?: string
  }
  setError?: (error: string) => void
  error?: string
  registry: UseFormRegisterReturn
  icon?: ReactElement
  leadingIcon?: ReactElement
  autoCheck?: {
    fn: (value: string) => Promise<boolean | undefined>
    messages?: { true: string; false: string }
  }
  score?: (value: string) => number
  scoreColors?: Record<number, string>
}

export function FormFieldInput(props: FormFieldInputProps) {
  const [status, setStatus] = useState<
    "idle" | "loading" | "error" | "success"
  >(props.error ? "error" : "idle")
  const [message, setMessage] = useState<
    { text: string; type: "success" | "error" } | undefined
  >(undefined)
  const [score, setScore] = useState<number | undefined>(0)
  const disabled =
    props.registry.disabled || props.inputHtmlAttributes?.disabled

  const getIcon = () => {
    switch (true) {
      case status === "loading":
        return <Loader className="absolute h-6 w-6 top-5 right-3" />
      case status === "error" || !!props.error:
        return (
          <SvgXCircle className="text-red-500 absolute h-6 w-6 top-5 right-3" />
        )
      case status === "success":
        return (
          <SvgCheckCircle className="text-green-500 absolute h-6 w-6 top-5 right-3" />
        )
      case disabled:
        return (
          <SvgLock className="text-gray-500 absolute h-6 w-6 top-5 right-3" />
        )
      default:
        return props.icon
          ? React.cloneElement(props.icon, {
              className: cn(
                props.icon.props.className,
                "absolute h-6 w-6 top-5 right-3",
              ),
            })
          : null
    }
  }

  const autoCheck = props.autoCheck?.fn
    ? debounce(async (event: ChangeEvent<HTMLInputElement>) => {
        setStatus("loading")
        const checked = await props.autoCheck?.fn(
          (event.target as HTMLInputElement).value,
        )

        if (checked === undefined) {
          setStatus("idle")
          setMessage(undefined)
        } else {
          setStatus(checked ? "success" : "error")
          setMessage({
            text: checked
              ? props.autoCheck?.messages?.true || "Looks good!"
              : props.autoCheck?.messages?.false || "Invalid input",
            type: checked ? "success" : "error",
          })
        }

        await props.registry.onChange(event)
      }, 700)
    : undefined

  const scoreCheck = props.score
    ? (event: ChangeEvent) =>
        props.score?.((event.target as HTMLInputElement).value)
    : undefined

  return (
    <Form.Field className="relative mb-2" name={props.registry.name}>
      <Form.Control asChild>
        <input
          {...props.inputHtmlAttributes}
          className={cn(
            "block bg-neutral-100 peer h-16 w-full rounded-lg border-none px-2.5 pb-0 pt-4 appearance-none",
            "focus:outline-none focus:ring-0",
            // safari will zoom in on input focus if font is < 16px so don't remove this 👇
            "max-sm:text-base",
            disabled ? "opacity-60 cursor-not-allowed" : "text-gray-900",
            {
              "pl-[1.6rem]": !!props.leadingIcon,
              "ring-1 ring-red-700": !!props.error,
            },
            props.inputHtmlAttributes?.className,
          )}
          type={props.inputHtmlAttributes?.type || "text"}
          placeholder={" "}
          {...props.registry}
          onChange={async (event) => {
            await props.registry.onChange(event)
            if (autoCheck) await autoCheck(event)
            if (scoreCheck) setScore(scoreCheck(event))
            props.inputHtmlAttributes?.onChange?.(event)
          }}
          onBlur={async (event) => {
            props.inputHtmlAttributes?.onBlur?.(event)
            await props.registry.onBlur(event)
          }}
        />
      </Form.Control>
      {!!props.leadingIcon && (
        <div className="pointer-events-none absolute inset-y-0 left-2.5 top-[30px] flex items-start opacity-100 transition-all duration-300 peer-placeholder-shown:opacity-0 peer-focus:opacity-100">
          {props.leadingIcon}
        </div>
      )}
      {!!props.score && !!score && (
        <div className="overflow-hidden rounded-b-full bg-neutral-100 relative bottom-[6px]">
          <div
            className={cn("h-[6px] rounded-b-full transition-all", {
              "bg-red-500": score < 0.2,
              "bg-yellow-500": score >= 0.2 && score < 0.5,
              "bg-green-500": score >= 0.5,
            })}
            style={{
              width: `${Math.round(Math.min(score, 1) * 100)}%`,
            }}
          />
        </div>
      )}
      <Form.Label
        className={clsx(
          "absolute duration-100 ease-in-out transform -translate-y-4 scale-75 top-5 origin-[0] start-2.5 text-gray-700",
          "peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-gray-500",
          "peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:text-gray-700",
        )}
      >
        {props.label}
      </Form.Label>
      {getIcon()}
      {(!!props.error || message?.type === "error") && (
        <Form.Message className={formErrorClassName}>
          {props.error || message?.text}
        </Form.Message>
      )}
      {!props.error && !!message && message.type === "success" && (
        <Form.Message className={formSuccessClassName}>
          {message.text}
        </Form.Message>
      )}
      {!props.error && !message?.text && !!props.caption && (
        <p
          className={cn(
            "text-xs opacity-[0.8] text-gray-500 grid grid-cols-[1fr_auto] gap-1 justify-between items-start h-fit pt-0.5",
            props.caption.className,
          )}
        >
          <span className="flex gap-1 truncate">
            {props.caption.icon !== false &&
              (props.caption.icon ?? (
                <SvgInfo className="h-4 w-4 min-w-4" />
              ))}{" "}
            <span className="truncate">{props.caption.text}</span>
          </span>
          {!!props.caption.action && (
            <Button
              type="button"
              variant="naked"
              onClick={async (event: MouseEvent<HTMLButtonElement>) => {
                if (!props.caption?.action) return
                props.caption.action.onClick(event)
              }}
              className="text-blue-500 hover:underline text-right"
            >
              {props.caption.action.text}
            </Button>
          )}
        </p>
      )}
    </Form.Field>
  )
}
