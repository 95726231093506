import * as React from "react"
import type { SVGProps } from "react"
const SvgCheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M1.042 10A8.958 8.958 0 0 1 10 1.042 8.958 8.958 0 0 1 18.958 10 8.958 8.958 0 0 1 10 18.958 8.958 8.958 0 0 1 1.042 10M10 2.292a7.708 7.708 0 1 0 0 15.416 7.708 7.708 0 0 0 0-15.416"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M12.942 7.891a.625.625 0 0 1 0 .884L9.609 12.11a.625.625 0 0 1-.884 0l-1.667-1.667a.625.625 0 1 1 .884-.884l1.225 1.225 2.891-2.892a.625.625 0 0 1 .884 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgCheckCircle
