import { UseFormReturn } from "react-hook-form"
import { isClerkAPIResponseError } from "@clerk/nextjs/errors"

export function assignClerkAPIErrorsToForm(
  form: UseFormReturn<any>,
  error: Error | any,
  mapping: Record<string, string> = {},
) {
  if (!isClerkAPIResponseError(error)) return

  for (const err of error.errors) {
    if (err?.meta?.paramName && mapping[err.meta.paramName]) {
      form.setError(mapping[err.meta.paramName], { message: err.longMessage })
    } else if (!err?.meta?.paramName) {
      form.setError("root", { message: err.longMessage })
    }
  }
}

export const formErrorClassName =
  "text-[13px] text-red-600 opacity-[0.8] block leading-tight h-fit pt-0.5"

export const formSuccessClassName =
  "text-[13px] text-green-600 opacity-[0.8] block leading-tight h-fit pt-0.5"
